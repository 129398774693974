export const SLIDER_CONFIG = {
  className: "GC-RN-slider",
  centerMode: true,
  infinite: false,
  arrows: false,
  initialSlide: 0,
  speed: 500,
  touchThreshold: 40,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

export const TRUST_WIDGET_CONFIG = {
  language: "en",
  culture: "US",
  theme: "light",
  width: "246px",
  height: "20px",
  template: "5419b6a8b0d04a076446a9ad",
  business: "58a605050000ff00059cd0f6",
  username: "amzsc.amzgb.com",
};

export const POSTS = [
  {
    title: "AMZ Scout is top of mind…",
    description: "AMZ Scout is top of mind when talking about selling on Amazon. It provides all the information you'll need to find the perfect niche and grow your future business.",
  },
  {
    title: "I really like this extension…",
    description: "I really like this extension. I have been using it for a month and find it to be ergonomic, easy to use, and reliable. The AMZScout customer service team has also been helpful and understanding. I give it 5 stars.",
  },
  {
    title: "This tool is a must-have for finding…",
    description: "This tool is a must-have for finding products to resell at a profit on Amazon. Very intuitive, but also has \"hints\" to help you along the way until you get comfortable with it.",
  },
];
