import JPG_One from "../../../assets/images/raster/pages/main/Guides/Guides_1.jpg";
import JPG_One_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_1@1200.jpg";
import JPG_One_992 from "../../../assets/images/raster/pages/main/Guides/Guides_1@992.jpg";
import JPG_One_768 from "../../../assets/images/raster/pages/main/Guides/Guides_1@768.jpg";
import JPG_One_mob from "../../../assets/images/raster/pages/main/Guides/Guides_1@mob.jpg";

import WEBP_One from "../../../assets/images/raster/pages/main/Guides/Guides_1.webp";
import WEBP_One_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_1@1200.webp";
import WEBP_One_992 from "../../../assets/images/raster/pages/main/Guides/Guides_1@992.webp";
import WEBP_One_768 from "../../../assets/images/raster/pages/main/Guides/Guides_1@768.webp";
import WEBP_One_mob from "../../../assets/images/raster/pages/main/Guides/Guides_1@mob.webp";

import JPG_Two from "../../../assets/images/raster/pages/main/Guides/Guides_2.jpg";
import JPG_Two_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_2@1200.jpg";
import JPG_Two_992 from "../../../assets/images/raster/pages/main/Guides/Guides_2@992.jpg";
import JPG_Two_768 from "../../../assets/images/raster/pages/main/Guides/Guides_2@768.jpg";
import JPG_Two_mob from "../../../assets/images/raster/pages/main/Guides/Guides_2@mob.jpg";

import WEBP_Two from "../../../assets/images/raster/pages/main/Guides/Guides_2.webp";
import WEBP_Two_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_2@1200.webp";
import WEBP_Two_992 from "../../../assets/images/raster/pages/main/Guides/Guides_2@992.webp";
import WEBP_Two_768 from "../../../assets/images/raster/pages/main/Guides/Guides_2@768.webp";
import WEBP_Two_mob from "../../../assets/images/raster/pages/main/Guides/Guides_2@mob.webp";

import JPG_Three from "../../../assets/images/raster/pages/main/Guides/Guides_3.jpg";
import JPG_Three_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_3@1200.jpg";
import JPG_Three_992 from "../../../assets/images/raster/pages/main/Guides/Guides_3@992.jpg";
import JPG_Three_768 from "../../../assets/images/raster/pages/main/Guides/Guides_3@768.jpg";
import JPG_Three_mob from "../../../assets/images/raster/pages/main/Guides/Guides_3@mob.jpg";

import WEBP_Three from "../../../assets/images/raster/pages/main/Guides/Guides_3.webp";
import WEBP_Three_1200 from "../../../assets/images/raster/pages/main/Guides/Guides_3@1200.webp";
import WEBP_Three_992 from "../../../assets/images/raster/pages/main/Guides/Guides_3@992.webp";
import WEBP_Three_768 from "../../../assets/images/raster/pages/main/Guides/Guides_3@768.webp";
import WEBP_Three_mob from "../../../assets/images/raster/pages/main/Guides/Guides_3@mob.webp";

import { URL_LABEL } from "../index.content";


const IDEA_GUIDES_LIST = [
  {
    screen: {
      PNG: JPG_One,
      PNG_1200: JPG_One_1200,
      PNG_992: JPG_One_992,
      PNG_768: JPG_One_768,
      PNG_mob: JPG_One_mob,
      WEBP: WEBP_One,
      WEBP_1200: WEBP_One_1200,
      WEBP_992: WEBP_One_992,
      WEBP_768: WEBP_One_768,
      WEBP_mob: WEBP_One_mob,
    },
    title: `How to Make Money on Amazon in ${process.env.YEAR}`,
    text: "Discover proven techniques and strategies that will allow you to build a successful Amazon business",
    href: `https://amzsc.amzgb.com/blog/how-to-make-money-on-amazon-a-step-by-step-guide/?${URL_LABEL}`,
  },
  {
    screen: {
      PNG: JPG_Two,
      PNG_1200: JPG_Two_1200,
      PNG_992: JPG_Two_992,
      PNG_768: JPG_Two_768,
      PNG_mob: JPG_Two_mob,
      WEBP: WEBP_Two,
      WEBP_1200: WEBP_Two_1200,
      WEBP_992: WEBP_Two_992,
      WEBP_768: WEBP_Two_768,
      WEBP_mob: WEBP_Two_mob,
    },
    title: `How to Find Products to Sell on Amazon in ${process.env.YEAR} Step-by-Step`,
    text: `Read our guide to learn how to find the most profitable products to sell on Amazon`,
    href: `https://amzsc.amzgb.com/blog/how-to-find-perfect-products-to-sell-on-amazon/?${URL_LABEL}`,
  },
  {
    screen: {
      PNG: JPG_Three,
      PNG_1200: JPG_Three_1200,
      PNG_992: JPG_Three_992,
      PNG_768: JPG_Three_768,
      PNG_mob: JPG_Three_mob,
      WEBP: WEBP_Three,
      WEBP_1200: WEBP_Three_1200,
      WEBP_992: WEBP_Three_992,
      WEBP_768: WEBP_Three_768,
      WEBP_mob: WEBP_Three_mob,
    },
    title: "Amazon FBA Business: 7-Step Guide from an Amazon Expert",
    text: "Learn the seven step checklist successful Amazon seller Alex Shelton used to build his very own eCommerce empire",
    href: `https://amzsc.amzgb.com/blog/amazon-fba-business/?${URL_LABEL}`,
  }
];

export default IDEA_GUIDES_LIST;
