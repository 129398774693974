import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const TrustWidget = ({ excellentText }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.Trustpilot.loadFromElement(
        document.querySelector(".trustpilot-widget"),
        true
      );
    };
    document.body.appendChild(script);

    return () => {
      if (window.Trustpilot) {
        window.Trustpilot.remove(".trustpilot-widget");
      }
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="PgEM-RO-TW">
      <p className="PgEM-RO-TW__text">
        <span>‘</span>
        {excellentText}
        <span>’</span>
      </p>
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="58a605050000ff00059cd0f6"
        data-style-height="20px"
        data-style-width="246px"
        data-theme="light"
      >
        <a
          className="PgEM-RO-TW__link"
          href="https://www.trustpilot.com/review/amzsc.amzgb.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};

TrustWidget.propTypes = {
  excellentText: PropTypes.string,
};

export default TrustWidget;
